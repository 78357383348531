// ------------------------------------------------------------
// pauseSvg.js
// ------------------------------------------------------------

import React from 'react';

// ------------------------------------------------------------

const PauseSvg = ({
    size = '100%',
    color = '#ffffff',
    style = { },
    className = '',
}) =>
<svg version="1.1" id="play" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 className={className} style={style} width={size} height={size} viewBox="0 0 96 96" enableBackground="new 0 0 96 96" xmlSpace="preserve">
    <g>
    	<path fill={color} d="M60,93.167c2.2,0,4-1.8,4-4V6.953c0-2.2-1.8-4-4-4l0,0c-2.2,0-4,1.8-4,4v82.214
    		C56,91.367,57.8,93.167,60,93.167L60,93.167z"/>
    </g>
    <g>
    	<path fill={color} d="M36,93.167c2.2,0,4-1.8,4-4V6.953c0-2.2-1.8-4-4-4l0,0c-2.2,0-4,1.8-4,4v82.214
    		C32,91.367,33.8,93.167,36,93.167L36,93.167z"/>
    </g>
</svg>

// ------------------------------------------------------------

export default PauseSvg;

// ------------------------------------------------------------
