// ------------------------------------------------------------
// playSvg.js
// ------------------------------------------------------------

import React from 'react';

// ------------------------------------------------------------

const PlaySvg = ({
    size = '100%',
    color = '#ffffff',
    style = { },
    className = '',
}) =>
<svg version="1.1" id="play" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 className={className} style={style} width={size} height={size} viewBox="0 0 96 96" enableBackground="new 0 0 96 96" xmlSpace="preserve">
    <g>
    	<path fill={color} d="M23.5,6c0-3.3,2.001-4.188,4.447-1.972l44.105,39.944c2.446,2.215,2.446,5.84,0,8.055L27.948,91.973
    		C25.502,94.188,23.5,93.3,23.5,90V6z"/>
    </g>
</svg>

// ------------------------------------------------------------

export default PlaySvg;

// ------------------------------------------------------------
