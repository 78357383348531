// ------------------------------------------------------------
// App.js
// ------------------------------------------------------------

import React from 'react';
import './App.scss';
import PlaySvg from './playSvg.js';
import PauseSvg from './pauseSvg.js';

// ------------------------------------------------------------
// Constants and Data

const AudioPlayerAnchor = 'genki-audio-player-anchor';
const AudioPlayerId = 'genki-audio-player';

const WorkbookFiles = [
    { name : 'W01-A.mp3', label : 'W01-A' },
	{ name : 'W01-B.mp3', label : 'W01-B' },
	{ name : 'W01-C.mp3', label : 'W01-C' },
	{ name : 'W01-D.mp3', label : 'W01-D' },
	{ name : 'W02-A.mp3', label : 'W02-A' },
	{ name : 'W02-B.mp3', label : 'W02-B' },
	{ name : 'W02-C.mp3', label : 'W02-C' },
	{ name : 'W03-A.mp3', label : 'W03-A' },
	{ name : 'W03-B.mp3', label : 'W03-B' },
	{ name : 'W03-C.mp3', label : 'W03-C' },
	{ name : 'W03-D.mp3', label : 'W03-D' },
	{ name : 'W04-A.mp3', label : 'W04-A' },
	{ name : 'W04-B.mp3', label : 'W04-B' },
	{ name : 'W04-C.mp3', label : 'W04-C' },
	{ name : 'W05-A.mp3', label : 'W05-A' },
	{ name : 'W05-B.mp3', label : 'W05-B' },
	{ name : 'W05-C.mp3', label : 'W05-C' },
	{ name : 'W06-A.mp3', label : 'W06-A' },
	{ name : 'W06-B.mp3', label : 'W06-B' },
	{ name : 'W06-C.mp3', label : 'W06-C' },
	{ name : 'W07-A.mp3', label : 'W07-A' },
	{ name : 'W07-B.mp3', label : 'W07-B' },
	{ name : 'W07-C.mp3', label : 'W07-C' },
	{ name : 'W08-A.mp3', label : 'W08-A' },
	{ name : 'W08-B.mp3', label : 'W08-B' },
	{ name : 'W08-C.mp3', label : 'W08-C' },
	{ name : 'W09-A.mp3', label : 'W09-A' },
	{ name : 'W09-B.mp3', label : 'W09-B' },
	{ name : 'W09-C.mp3', label : 'W09-C' },
	{ name : 'W10-A.mp3', label : 'W10-A' },
	{ name : 'W10-B.mp3', label : 'W10-B' },
	{ name : 'W10-C.mp3', label : 'W10-C' },
	{ name : 'W11-A.mp3', label : 'W11-A' },
	{ name : 'W11-B.mp3', label : 'W11-B' },
	{ name : 'W11-C.mp3', label : 'W11-C' },
	{ name : 'W12-A.mp3', label : 'W12-A' },
	{ name : 'W12-B.mp3', label : 'W12-B' },
	{ name : 'W12-C.mp3', label : 'W12-C' },
];

//const HiraganaGenki = 'げんき Workbook Audio'; // TODO want to use this but the hiragana is taller than Latin chars and so the position of things jumps/shifts when we go from this to only Latin
const LatinGenki = 'Genki Workbook Audio';

const Speed = {
    normal : 'normal',
    slow : 'slow',
    slowest : 'slowest',
};

const Rate = {
    normal : 1.0,
    slow : 0.8,
    slowest : 0.6,
};

// ------------------------------------------------------------

class App extends React.Component {
    constructor( ) {
        super( );
        this.state = {
            selected : null,
            selectedLabel : LatinGenki,
            speed : Speed.normal,
            playing : false,
            loading : false, // TODO currently don't do any loading
            audioDuration : 0,
            audioPosition : 0,
            unsub : null,
        };
    }

    // ------------------------------------------------------------

    componentDidMount( ) {
        const unsub = setInterval( ( ) => {
            const player = document.getElementById( AudioPlayerId );
            if ( player !== null ) {
                // sometimes player.duration returns NaN, maybe when the source is still loading?
                if ( !isNaN( player.duration ) ) {
                    const duration = Math.floor( player.duration );
                    const position = Math.floor( player.currentTime );
                    this.setState({
                        audioDuration : duration,
                        audioPosition : position,
                    });
                }
            }
        }, 200 );
        this.setState({ unsub });
    }

    // ------------------------------------------------------------

    componentWillUnmount( ) {
        clearInterval( this.state.unsub );
    }

    // ------------------------------------------------------------

    render( ) {
        return (
            <div>
                {this.renderPlayer( )}
                <div id={AudioPlayerAnchor} className=''></div>
                {this.renderSelection( )}
            </div>
        );
    }

    // ------------------------------------------------------------

    renderPlayer( ) {
        const playDisplay = ( this.state.playing ) ? { display : 'none' } : { };
        const pauseDisplay = ( !this.state.playing ) ? { display : 'none' } : { };

        return (
            <div className="playerDiv">
                <div className=''></div>
                <div className="headerText">{this.state.selectedLabel}</div>
                <div className="playPauseButton" onClick={( ) => { this.clickedPlayPause( ) }}>
                    <PlaySvg style={playDisplay} size={48} />
                    <PauseSvg style={pauseDisplay} size={48} />
                </div>
                <div className="speedRow">
                    {this.renderSpeedItem( Speed.normal )}
                    {this.renderSpeedItem( Speed.slow )}
                    {this.renderSpeedItem( Speed.slowest )}
                </div>
                {this.renderTimeBar( )}
            </div>
        );
    }

    // ------------------------------------------------------------

    renderTimeBar( ) {
        const totalSections = this.state.audioDuration;
        const playedSections = this.state.audioPosition;
        const unplayedSections = totalSections - playedSections;

        return (
            <div className="timeBarDiv">
                {[...Array(playedSections)].map( ( o, i ) => {
                    return <div
                        key={'played-' + i}
                        className="timeBarSection filled"
                        onClick={( ) => { this.goToPosition( i ) }}
                        ></div>;
                } )}
                {[...Array(unplayedSections)].map( ( o, i ) => {
                    return <div
                        key={'unplayed-' + i}
                        className="timeBarSection"
                        onClick={( ) => { this.goToPosition( playedSections + i ) }}
                        ></div>;
                } )}
            </div>
        );
    }

    // ------------------------------------------------------------

    goToPosition( seconds ) {
        const player = document.getElementById( AudioPlayerId );
        player.currentTime = seconds;
        this.setState({ audioPosition : seconds });
    }

    // ------------------------------------------------------------

    renderSpeedItem( speed ) {
        const itemClass = ( this.state.speed === speed ) ? 'speedItemactive' : 'speedItem';
        return <div className={itemClass} onClick={( ) => { this.changeSpeed( speed ) }}>{speed}</div>
    }

    // ------------------------------------------------------------

    changeSpeed( speed ) {
        let rate = Rate.normal;

        if ( speed === Speed.slow ) {
            rate = Rate.slow;
        } else if ( speed === Speed.slowest ) {
            rate = Rate.slowest;
        }

        if ( speed !== this.state.speed ) {
            // if we are changing speed
            this.setState({ speed }, ( ) => {
                if ( this.state.selected !== null ) {
                    // if a player already exists, in other words
                    const player = document.getElementById( AudioPlayerId );
                    player.playbackRate = rate;
                }
            });
        }
    }

    // ------------------------------------------------------------

    renderSelection( ) {
        return (
            <div className="selectionDiv">
                {WorkbookFiles.map( file => {
                    const selectionClass = ( file.name === this.state.selected ) ? "selectionItem activeItem" : "selectionItem";
                    return (
                        <div className={selectionClass}
                            key={file.name}
                            onClick={( ) => { this.clicked( file ) }}
                        >{file.label}</div>
                    );
                } )}
            </div>
        );
    }

    // ------------------------------------------------------------

    clicked( file ) {
        const current = this.state.selected;

        if ( this.state.selected !== file.name ) {
            this.setState({ selected : file.name, selectedLabel : file.label, playing : true }, ( ) => {
                // remove current audio player if one exists
                if ( current != null ) {
                    const player = document.getElementById( AudioPlayerId );
                    player.remove( );
                }
                this.createAudioPlayer( file.name );
            });
        } else {
            // start play over from beginning
            const player = document.getElementById( AudioPlayerId );
            player.currentTime = 0;
        }
    }

    // ------------------------------------------------------------

    createAudioPlayer( filename ) {
        let rate = Rate.normal;

        if ( this.state.speed === Speed.slow ) {
            rate = Rate.slow;
        } else if ( this.state.speed === Speed.slowest ) {
            rate = Rate.slowest;
        }

        const AudioPlayer = document.createElement( 'audio' );
        const audioUrl = '/workbook/' + filename;
        AudioPlayer.setAttribute( 'id', AudioPlayerId );
        AudioPlayer.setAttribute( 'src', audioUrl );
        AudioPlayer.playbackRate = rate;
        AudioPlayer.onended = ( ) => { this.audioEnded( ) };
        document.getElementById( AudioPlayerAnchor ).appendChild( AudioPlayer );
        AudioPlayer.play( ).then( ).catch( ); // TODO catch error, handle, etc...
        //return AudioPlayer;
    }

    // ------------------------------------------------------------

    audioEnded( ) {
        this.setState({ playing : false });
    }

    // ------------------------------------------------------------

    clickedPlayPause( ) {
        if ( this.state.selected === null ) {
            // if nothing selected yet, grab the first option and create player
            this.clicked( WorkbookFiles[0] );
        } else {
            // otherwise, toggle playing/pause in state and audio
            this.togglePlay( );
        }
    }

    // ------------------------------------------------------------

    togglePlay( ) {
        const playing = this.state.playing;
        this.setState({ playing : !this.state.playing }, ( ) => {
            const player = document.getElementById( AudioPlayerId );
            if ( playing ) {
                player.pause( );
            } else {
                player.play( );
            }
        });
    }

    // ------------------------------------------------------------

}

// ------------------------------------------------------------

export default App;

// ------------------------------------------------------------
